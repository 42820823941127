.bottomContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #FFFFFF;
    padding: 20;
    background: linear-gradient(to bottom right, 
    #52BDAA 0%, #FFFFFF 50%);
}
.bottomContainer #contentContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  
  
}
#bottomtitle{
  font-family: 'Ubuntu';
font-style: normal;
font-weight: 700;
font-size: 1.5rem;



color: #000033;
}
#bottomtext{
  font-family: 'Ubuntu';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 24px;
letter-spacing: 0.5;
/* or 162% */

display: flex;
align-items: center;
text-align: justify;
}

@media (max-width: 650px) {
    .bottomContainer {
      flex-wrap: wrap;
    }
    .bottomContainer #contentContainer{
      width: 90%;
      margin-bottom: 20px;
    }
    
  }