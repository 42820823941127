.navbox {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-left: 230px;
  padding-right: 230px;
  z-index: 1;
}


#home{
  text-decoration: none;
  color: #000033;
  border-bottom: 2px solid #f3943f;
}
#privacy{
  text-decoration: none;
  color: #000033;
  border-bottom:none;
}
#accountDelete{
  text-decoration: none;
  color: #000033;
  border-bottom:none;
}

.brandContainer {
  display: flex;
  flex-direction: row;
}

.brandImage {
  width: 54px;
  height: 58px;
}

.navLinksContainer {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}
.boxing{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}

.dotRow {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(0, 0, 51, 0.15);
  margin: 0 5px;
}
@media (max-width: 981px) {
  .navbox {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-left: 50px;
    padding-right: 50px;
    z-index: 1;
  }
  
}