.image-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Space between images */
    justify-content: center;
  }
  
  /* For larger screens, split images into two rows with two columns */
  .image-grid img {
    width: 300px;
  }
  
  @media (min-width: 601px) {
    .image-grid {
      /* Two columns in two rows */
      max-width: 650px; /* Adjust as needed */
    }
  }
  
  /* For smaller screens, stack images vertically */
  @media (max-width: 600px) {
    .image-grid {
      flex-direction: column; /* Stack images vertically */
    }
  
    .image-grid img {
      width: 100%; /* Make images full width on small screens */
    }
  }
  